import React, { ReactNode } from 'react';
import Container from '../components/core/layout/Layout/Container';
import Heading from '../components/core/typography/Heading';
import List from '../components/core/lists/List';
import ListItem from '../components/core/lists/ListItem';
import Button from '../components/core/buttons/Button';
import FaqAccordion from '../components/views/FaqAccordion/FaqAccordion';
import SEO from '../components/core/meta/SEO';
import { graphql, Link, useStaticQuery } from 'gatsby';
import {
  getAppRedirectUri,
  HOSTNAME,
} from '../components/utils/getAppRedirectUri';
import { PrezziCompagniaQuery } from '../../generated/graphql-types';
import OrangeBanner2 from '../components/partials/OrangeBanner2/OrangeBanner2';
import SecurePayment from '../components/partials/SecurePayment/SecurePayment';
import ThreeSteps from '../components/partials/ThreeSteps/ThreeSteps';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { renderOptions } from '../contentful/render-options';
import PartnerBanner from '../components/partials/PartnerBanner/PartnerBanner';
import OtherServices from '../components/views/OtherServices/OtherServices';
import useDatalayer from '../../hooks/use-datalayer';

const PricesCompanyPage = () => {
  const [appLocation, setAppLocation] = React.useState<string>(
    '//app.' + HOSTNAME
  );

  const datalayer = useDatalayer();

  React.useEffect(() => {
    setAppLocation(getAppRedirectUri());
  }, []);

  const prezziCompagnia = useStaticQuery<PrezziCompagniaQuery>(graphql`
    query PrezziCompagnia {
      compagniaFaq: allContentfulFaq(
        filter: { category: { eq: "Prezzi Compagnia Page" } }
      ) {
        edges {
          node {
            question {
              question
            }
            answer {
              raw
            }
          }
        }
      }
    }
  `);

  return (
    <div className="pb-16">
      <SEO title="Prezzi compagnia" url="/prezzi-compagnia" />
      <section className="py-10">
        <Container>
          <Heading variant="h1" className="mb-10">
            Costi del servizio di compagnia
          </Heading>
          <Heading variant="h3">Compagnia al telefono o in presenza</Heading>
          <p className="text-paragraph mb-16">
            Il servizio di compagnia nasce per{' '}
            <b>combattere solitudine e isolamento</b>. Ci siamo chiesti come
            mettere a disposizione in questo momento così complesso la nostra
            rete di operatori per portare un po’ del nostro estusiasmo e della
            nostra passione nelle case. Con i nostri operatori UGO è possibile{' '}
            <b>
              trascorre del tempo non solo uscendo ma anche chiacchierando al
              telefono o in video chiamata.
            </b>{' '}
            Nel prossimo futuro sarà possibile anche trascorrere tempo di
            qualità in casa, giocando a carte con un tè caldo; oggi preferiamo
            ridurre al minimo i contatti. Puoi richiedere il servizio di
            compagnia per te o per una persona cara.
          </p>
        </Container>
        <div className="bg-primary-300 py-16">
          <Container>
            <div className="flex flex-wrap -mx-5 mb-5">
              <div className="w-full md:w-1/2 px-5 flex">
                <div className="p-8 rounded-card shadow-card bg-white flex flex-col flex-1">
                  <Heading variant="h4" className="mb-5 text-center">
                    30 minuti al telefono
                  </Heading>
                  <div className="text-center mb-5">
                    <span className="text-paragraph text-3xl font-bold text-primary-500">
                      €
                    </span>
                    <span className="text-paragraph text-7xl font-bold text-primary-500">
                      5,00
                    </span>
                  </div>
                  <div className="flex md:justify-center">
                    <List>
                      <ListItem className="font-bold">
                        Prenoti per te o per una persona cara.
                      </ListItem>
                      <ListItem className="font-bold">
                        L’operatore telefona per presentarsi e chiacchierare,
                        condividendo storie ed esperienze.
                      </ListItem>
                      <ListItem className="font-bold">
                        Parlare al telefono con uno sconosciuto non è cosa
                        facile ma è un’esperienza bellissima.
                      </ListItem>
                    </List>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-5 flex">
                <div className="p-8 rounded-card shadow-card bg-white flex flex-col flex-1">
                  <Heading variant="h4" className="mb-5 text-center">
                    1 ora in presenza
                  </Heading>
                  <div className="text-center mb-5">
                    <span className="text-paragraph text-3xl font-bold text-primary-500">
                      €
                    </span>
                    <span className="text-paragraph text-7xl font-bold text-primary-500">
                      15,00
                    </span>
                  </div>
                  <div className="flex md:justify-center">
                    <List>
                      <ListItem className="font-bold">
                        La compagnia in presenza, oggi limitata per motivi di
                        sicurezza e prevenzione, ricade sotto il servizio di
                        accompagnamento e può svolgersi in casa o facendo una
                        passeggiata all’aria aperta. Sei tu a scegliere come
                        trascorrere il tempo col nostro operatore.
                      </ListItem>
                    </List>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <Container className="py-16">
          <ThreeSteps />
        </Container>
        <OrangeBanner2 />
        <Container className="py-16 pb-20">
          <SecurePayment />
        </Container>
        <Container>
          <div className="flex justify-center">
            <Link to="/#">
              <Button variant="filled" color="primary">
                Prenota il servizio
              </Button>
            </Link>
          </div>
        </Container>
      </section>
      {prezziCompagnia.compagniaFaq.edges.length && (
        <section className="pb-16">
          <Container>
            <Heading variant="h2" className="mt-0">
              Domande frequenti
            </Heading>
            <FaqAccordion
              faqItems={prezziCompagnia.compagniaFaq.edges.map(({ node }) => ({
                answer: renderRichText(
                  // @ts-ignore
                  node.answer,
                  renderOptions
                ) as ReactNode,
                question: node.question.question,
              }))}
            />
          </Container>
        </section>
      )}
      <PartnerBanner />
      <Container className="pt-16">
        <OtherServices commissioni accompagnamento />
      </Container>
    </div>
  );
};

export default PricesCompanyPage;
